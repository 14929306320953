$(document).ready(function () {
    // ログイン情報の取得
    let mail_adder = localStorage.getItem('user.mail_adder');
    
    if (mail_adder) {
        $('#mail_adder_dialog').val(mail_adder);
        $('#remember_dialog').prop('checked', true);
    }
});

$(function(){
    let clickEventType = (( window.ontouchstart!==null ) ? 'click':'touchend');
    $(document).on(clickEventType,'.dialog-open',function(){
        let next = $(this).data('dialog-next');
        if (next !== undefined) {
            let url = location.href;
            let item_unique_key = $(this).data('item-unique-key');
            if (next) {
                $('[name=next_page]').val(next);
            } else {
                // ログイン後親画面リロード
                $('[name=next_page]').val(url);
                $('[name=item_unique_key]').val(item_unique_key);
            }
        }
    });
});

$(document).on('submit', '#login_form_dialog', function (e) {
    // イベント中止
    e.preventDefault();
    
    // ID情報を保持するチェック時
    let isCheck = $('#remember_dialog').prop('checked');
    if (isCheck) {
        localStorage.setItem('user.mail_adder', $('#mail_adder_dialog').val());
    } else {
        localStorage.removeItem('user.mail_adder');
    }
    
    let $form = $(this);
    
    $.ajax({
        url: $form.attr('action'),
        type: $form.attr('method'),
        data: $form.serializeArray(),
        dataType: 'json',
    }).done(function (data) {
        console.log(data)
        window.location.href = data.redirect;
    }).fail(function (data) {
    });
});

